<template>
  <div>
    <template v-if="system_key!=='intelligent-elevator'">
      <b-card :style="'opacity:' + (equip_item.equip_name ? '1' : '0')" no-body
              style="width: 22rem">
        <b-card-title class="pt-1 px-2 m-0">
          <span>{{ equip_item.equip_name }}</span>
        </b-card-title>
        <b-card-text v-if="equip_item.location" class="m-0">
          <small class="px-2">位置：{{ equip_item.location }}</small>
        </b-card-text>
        <hr/>
        <b-card-text class="m-0">
          <!--      详细模型-->
          <template v-if="!!equip_item.detail_model_key">
            <b-row class="mb-1" no-gutters>
              <b-col cols="12">
                <div class="w-100" style="height: 10rem">
                  <loading v-show="loading_show" :is-show="loading_show" class="w-100 h-100"/>
                  <div id="cardModel" :style="'opacity:' + (loading_show ? '0' : '1')" class="w-100 h-100"></div>
                  <b-button
                      v-b-tooltip.hover="isDetailModel?'整体模型':'详细模型'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="btn-icon position-absolute"
                      size="sm"
                      style="right: 0.5rem; top: 0.5rem; z-index: 3; padding: 0.2rem"
                      :variant="isDetailModel?'success':'primary'"
                      :style="'opacity:' + (loading_show ? '0' : '1')"
                      @click="$emit('switchModel');isDetailModel = !isDetailModel"
                  >
                    <feather-icon :icon="isDetailModel?'MinimizeIcon':'MaximizeIcon'"/>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <hr/>
          </template>
          <!--      监控画面-->
          <template v-if="equip_item.ip_address">
            <b-row class="mb-1" no-gutters>
              <b-col class="position-relative" cols="12">
                <div class="position-relative w-100" style="height: 13rem">
                  <video v-show="!videoPlayerError" id="info-card-video" class="h-100 w-100"></video>
                  <div v-if="videoPlayerError" class="h-100 w-100 text-center pt-4 position-absolute"
                       style="top: 0; background-color: rgba(0, 0, 0, 0.5)">
                    <h6>播放异常</h6>
                    <small>{{ videoPlayerError }}</small>
                  </div>
                  <div>
                    <b-button
                        v-b-tooltip.hover
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon position-absolute"
                        size="sm"
                        style="right: 0.5rem; top: 0.5rem; z-index: 3; padding: 0.2rem"
                        variant="primary"
                        @click="videoModelShow = true"
                    >
                      <feather-icon icon="MaximizeIcon"/>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
            <hr/>

          </template>
          <!--      参数列表-->
          <vue-perfect-scrollbar
              :settings="{ maxScrollbarLength: 60, wheelPropagation: true }"
              class="px-2 overflow-hidden mb-0"
              style="max-height: 20rem;height: auto"
              tagname="div"
              @ps-scroll-y="
					(evt) => {
						shallShadowBottom = evt.srcElement.scrollTop > 0;
					}
				"
          >
            <div v-if="params_group !== {}">
              <div v-show="params_group['status'] && params_group['status'].length > 0">
                <!--            <h6>状态参数</h6>-->
                <div v-for="(v, i) in params_group['status']" v-if="v.param_name" :key="i"
                     class="d-flex align-items-center justify-content-between" style="margin-bottom: 0.2rem">
                  <small>{{ explode_params(v.param_name) }}</small>

                  <span>
								<small v-if="v.unit&&(v.unit.indexOf('||') > -1)">
									<strong>{{ v.unit.split("||")[v.cur_value > 0 ? 1 : 0] }}</strong>
								</small>
								<small v-else>
									<strong>{{ v.cur_value !== null ? v.cur_value : "N/A" }}</strong>
									<small>{{ v.unit }}</small>
								</small>
							</span>
                </div>
              </div>
              <div v-show="params_group['control'] && params_group['control'].length > 0">
                <hr/>
                <!--            <h6>控制参数</h6>-->
                <div v-for="(v, i) in params_group['control']" v-if="v.param_name" :key="i"
                     class="d-flex align-items-center justify-content-between" style="margin-bottom: 0.2rem">
                  <small>{{ explode_params(v.param_name) }}</small>
                  <small class="text-right">
                    <small v-if="v.unit.indexOf('||') > 0">
                      <b-form-checkbox v-model="v.cur_value" class="m-0" inline switch @change="update_command(v)">
                        <strong>{{ v.unit.split("||")[v.cur_value > 0 ? 1 : 0] }}</strong>
                      </b-form-checkbox>
                    </small>
                    <small v-else>
                      <b-form-input v-model="v.cur_value" class="text-right" inline number size="sm"
                                    style="width: 5.2rem"
                                    @change="update_command(v)"></b-form-input>
                      <small v-if="v.unit.indexOf('||') < 0 && v.unit" class="mr-50">{{ v.unit }}</small>
                    </small>
                  </small>
                </div>
                <div class="mt-1 position-relative">
                  <div class="w-50 d-inline-block">
                    <b-form-checkbox class="m-0 position-relative" inline style="top: 0.1rem" switch>
                      <span style="font-size: 1rem">强制</span>
                    </b-form-checkbox>
                  </div>
                  <div class="w-50 d-inline-block">
                    <b-button block size="sm" variant="primary" @click="submit_command()">设定</b-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="text-center">此设备暂无绑定参数</p>
            </div>
          </vue-perfect-scrollbar>

          <template>
            <hr/>
            <div class="px-2 pb-1">
              <h5>故障处理提示</h5>
              <p v-html="equip_item.error_handle"></p>
            </div>
          </template>

        </b-card-text>
      </b-card>
    </template>

    <template v-else>
      <elevator-item-info-card v-if="equip_item.equip_name" :lift_name="equip_item.equip_name" @video-play="videoModelShow = true"></elevator-item-info-card>
    </template>
    <b-modal v-model="videoModelShow" centered hide-footer size="lg">
      <div class="position-relative" style="height: 48rem">
        <video v-show="!videoModelPlayerError" id="info-card-model-video" class="h-100 w-100"></video>
        <div v-if="videoModelPlayerError" class="h-100 w-100 text-center pt-4 position-absolute"
             style="top: 0; background-color: rgba(0, 0, 0, 0.5)">
          <h6>播放异常</h6>
          <small>{{ videoModelPlayerError }}</small>
        </div>
      </div>
    </b-modal>

  </div>

</template>

<script>
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {setupViewer} from "@/libs/forge/app";
import Loading from "./ModelLoading";
import store from "@/store";
import {destroyViewer, getModelPath} from "@/libs/forge/utils/tools";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {showToast} from "@/libs/utils/showToast";
import flvjs from "flv.js";
import Ripple from "vue-ripple-directive";
import {routerParams} from "@/libs/utils/routerParams";
import ElevatorItemInfoCard
  from "@/views/front/jsf35/subsystems/components/intelligent-elevator/elevator_item_info_card";

export default {
  name: "ModelInfoCard",
  props: {
    time: Number | String,
  },
  directives: {
    Ripple,
  },
  components: {
    Loading,
    ElevatorItemInfoCard,
    VuePerfectScrollbar,
  },
  setup(props) {
    const system_key = routerParams("system_key");
    const viewer = ref(null);
    //两个视频播放实例
    const videoPlayer = ref(null);
    const videoPlayerError = ref(null);
    const videoModelShow = ref(false);
    const videoModelPlayer = ref(null);
    const videoModelPlayerError = ref(null);
    const isDetailModel = ref(false);
    const loading_show = ref(false);
    const check = ref(false);
    const getMes = computed(() => {
      return function (data, key) {
        return data[key];
      };
    });
    const show = ref(false);
    const activeIndex = ref(null);
    const inputVal = ref(null);
    const params_group = ref({});
    const updated_command_items = ref({});
    const fake = ref([
      {
        label: "流量",
        value: 10,
        edit: false,
      },
      {
        label: "风速",
        value: 20,
        edit: true,
      },
      {
        label: "转速",
        value: 30,
        edit: true,
      },
    ]);
    const shallShadowBottom = ref(false);
    const setVal = (i) => {
      show.value = !show.value;
      activeIndex.value = i;
      !show.value ? (fake.value[i].value = inputVal.value) : (inputVal.value = fake.value[i].value);
    };

    const equip_item = computed(() => {
      return Object.assign({}, store.state.jsf35.equip_selected);
    });

    watch(equip_item, (equip_item) => {
      updated_command_items.value = {};
      if (equip_item.params && equip_item.params.length > 0) {
        params_group.value = {
          status: equip_item.params.filter((param) => param.is_control !== 1),
          control: equip_item.params.filter((param) => param.is_control === 1),
        };
      } else {
        params_group.value = {};
      }

      //若存在详细模型则显示详细模型
      if (equip_item.detail_model_key) {
        setTimeout(() => {
          const box = document.getElementById("cardModel");
          loading_show.value = true;
          if (viewer.value) {
            destroyViewer(viewer.value);
          }
          setupViewer(box, viewer, getModelPath(72, equip_item.detail_model_key), ["TurnTable", "MyAwesomeExtension", "SelectModel"], {
            ids: equip_item.dbId,
          });
        }, 1000)

      }
      //若存在视频则播放视屏
      if (equip_item.ip_address && system_key!=='intelligent-elevator') {
        videoPlay(equip_item.ip_address);
      }
    });

    watch(viewer, (newVal) => {
      if (newVal) {
        newVal.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
          loading_show.value = false;
          // if (props.equip_item.dbId) {
          //   // newVal.select([props.equip_item.dbId], newVal.model, Autodesk.Viewing.SelectionType.REGULAR)
          //   newVal.fitToView([props.equip_item.dbId]);
          //   // newVal.isolate([props.equip_item.dbId])
          // }

          if (equip_item.model_rotate) {
            const pos = equip_item.model_rotate.split(",").map((item) => Number(item));
            newVal.navigation.setPosition(new THREE.Vector3(pos[0], pos[1], pos[2]));
          }

          newVal.fitToView(equip_item.dbId || []);
        });
      }
    });

    watch(videoModelShow, () => {
      if (videoModelShow.value) {
        setTimeout(() => {
          videoPlay(store.state.jsf35.equip_selected.ip_address, true);
        }, 1000);
      } else {
        if (videoModelPlayer.value) {
          videoModelPlayer.value.detachMediaElement();
          videoModelPlayer.value.destroy();
          videoModelPlayer.value = null;
          videoModelPlayerError.value = null;
        }
      }
    });

    //初始化视频播放器，并开始播放
    const videoPlay = (url, isModel = false) => {
      if (flvjs.isSupported()) {
        if (videoPlayer.value && !isModel) {
          videoPlayer.value.detachMediaElement();
          videoPlayer.value.destroy();
          videoPlayer.value = null;
          videoPlayerError.value = null;
        }
        if (videoModelPlayer.value && isModel) {
          videoModelPlayer.value.detachMediaElement();
          videoModelPlayer.value.destroy();
          videoModelPlayer.value = null;
          videoModelPlayerError.value = null;
        }
        const flvVideo = flvjs.createPlayer({
          type: "flv",
          url: url,
        });
        let videoDom;
        if (isModel) {
          videoDom = document.getElementById("info-card-model-video");
        } else {
          videoDom = document.getElementById("info-card-video");
        }
        flvVideo.attachMediaElement(videoDom);
        flvVideo.load();
        let error_msg = "";
        flvVideo.on("error", function (error) {
          error_msg = error;
          showToast(null, "播放错误", "安防设备视频画面仅供内网访问");
          if (isModel) {
            videoModelPlayerError.value = "安防设备视频画面仅供内网访问";
          } else {
            videoPlayerError.value = "安防设备视频画面仅供内网访问";
          }
        });
        //没有异常信息时播放
        if (!error_msg) {
          if (isModel) {
            videoModelPlayer.value = flvVideo;
          } else {
            videoPlayer.value = flvVideo;
          }
          flvVideo.play();
        }
      }
    };

    const explode_params = (param_name) => {
      //console.log('param_name', param_name)
      let str = param_name;
      if (param_name) {
        let name_arr = param_name.split("-");
        str = name_arr[name_arr.length - 1];
      }
      return str;
    };

    //发送请求修改控制参数
    const submit_command = () => {
      const list = Object.values(updated_command_items.value);
      store
          .dispatch("jsf35/submitCommand", {project_id: 72, list: list})
          .then((response) => {
            showToast(null, "成功", "指令已下发成功！");
          })
          .catch((error) => {
            showToast(error);
          });
    };
    //记录已更新的控制参数
    const update_command = (param_item) => {
      updated_command_items.value[param_item.tag_name] = {
        tag_name: param_item.tag_name,
        cur_value: param_item.cur_value,
      };
    };

    onMounted(() => {
      const box = document.getElementById("cardModel");
      if (equip_item.detail_model_key) {
        setupViewer(box, viewer, equip_item.detail_model_key, ["TurnTable", "MyAwesomeExtension", "SelectModel"], {
          ids: equip_item.dbId || [],
        });
      }
    });

    return {
      system_key,
      shallShadowBottom,
      getMes,
      loading_show,
      fake,
      show,
      activeIndex,
      inputVal,
      check,
      setVal,
      equip_item,
      explode_params,
      params_group,
      submit_command,
      update_command,
      videoPlayerError,
      videoModelPlayerError,
      videoModelShow,
      isDetailModel
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-lg {
  max-width: 60rem !important;
}
</style>
